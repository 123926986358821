import React from "react";

import Box from "../components/box";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Img from "../components/img";
import { SmallTitle } from "../components/text";

import useEntryAnimation from "../utility/useEntryAnimation";

function Contacts() {
  const container = useEntryAnimation();

  return (
    <Layout bg={false}>
      <SEO title="Contacts" />

      <div ref={container}>
        <Box my={5} mt={6}>
          <SmallTitle data-reveal>Team</SmallTitle>
          <Img data-reveal="image" my={3} src="https://picsum.photos/400/400" />
          <Img data-reveal="image" my={3} src="https://picsum.photos/400/400" />
          <Img data-reveal="image" my={3} src="https://picsum.photos/400/400" />
          <Img data-reveal="image" my={3} src="https://picsum.photos/400/400" />
          <Img data-reveal="image" my={3} src="https://picsum.photos/400/400" />
        </Box>
      </div>
    </Layout>
  );
}

export default Contacts;
